import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { FirebaseAuthProvider } from './FirebaseAuthContext';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(
  <HelmetProvider>
    <FirebaseAuthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </FirebaseAuthProvider>
  </HelmetProvider>
);
