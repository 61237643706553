import React from 'react';
import { Link as RouterLink } from "react-router-dom"
// @mui
import { useTheme } from '@mui/material/styles';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Link, Box } from '@mui/material';
// components
import Page from '../components/Page';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Pricing from '../sections/pricing/Pricing';


// ----------------------------------------------------------------------
const extensionLink = "https://airtable.com/marketplace/blk699kvxC9Zyo8Vm/excel-exporter"
const faq: { question: string, answer: React.ReactNode }[] = [
    {
        question: "How can I install this extension",
        answer: <span>You can find and install the extension on <Link href={extensionLink}>the airtable marketplace</Link></span>
    },
    {
        question: "How many rows can an Excel file contain",
        answer: "The number of rows is limited to 100 in the free version. With the premium subscription, it is limited to 50'000 per sheet. Please contact us if you need more!",
    },
    {
        question: "Can I customize the table style",
        answer: "With the premium version, you can choose among all excel pre-defined styles"
    },
    {
        question: "Can I make columns sortable and filterable",
        answer: "With the premium version, you can make all columns sortable and filterable"
    },
    {
        question: "How many sheets per file can I have at most?",
        answer: "There are currently no page limits per file, and each page can contain up to 50,000 lines"
    },
    {
        question: `Airtable can't install the extension: "This extension is disabled due to enterprise restrictions"`,
        answer: <span>
            You need to review your policy towards extensions in the Airtable admin panel. See the <Link href="https://support.airtable.com/docs/settings-airtable-enterprise-admin-panel#extensions-integrations-sync">
                airtable documentation
            </Link>
            <br />

            Our extension reaches out to non-Airtable servers to manage users and subscriptions.
            <br />

            Rest assured that there is 0 Airtable data that is sent to our servers.
            <br />

        </span>

    }, 
    {
        question: "How many sheets per file can I have at most?",
        answer: "For premium users, there are currently no page limits per file, and each page can contain up to 50,000 lines. In the free plan there is 1 sheet per file"
    }
]


export default function DashboardApp() {
    const theme = useTheme();

    return (
        <Page title="Dashboard">
            <Container maxWidth="xl">
                <Typography variant="h4" fontSize={5} component="h2">
                Powerful Airtable Data Export to Excel with Images

                </Typography>
                <Typography variant="subtitle1" sx={{ mb: 5 }}>
                    Export Airtable views to Excel, in .xlsx format!
                    <br />
                    <Link href={extensionLink}>Install on the Airtable marketplace!</Link>
                </Typography>

                <Typography variant="h4">Plans</Typography>
                <br />
                <Pricing />
                <br />
                <Typography variant="h4">
                    FAQ
                </Typography>
                <br />
                <div>
                    {faq.map(({ question, answer }, i) =>
                        <Accordion key={i}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="subtitle1">{question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )}
                </div>
                <br />
                <Typography variant="h4">
                    Contact us
                </Typography>
                <br />
                <Typography variant="body1">
                    Send us an email to <Link href="mailto:contact@excel-exporter.com">contact@excel-exporter.com</Link> if you have any question or suggestion
                </Typography>
                <br />
                <Typography variant="h4">
                    Legal
                </Typography>
                <br />
                <Link component={RouterLink} to="/legal/privacy-policy">Privacy Policy</Link>
                <br />
                <Link component={RouterLink} to="/legal/terms-and-conditions">Terms And Conditions</Link>

            </Container>
        </Page >
    );
}
