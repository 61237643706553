import * as React from "react";
import { initializeApp } from "firebase/app";
import {
    Auth,
    createUserWithEmailAndPassword,
    getAuth,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    updateProfile,
    User,
    UserCredential,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions"
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"
import { translate, AuthEN } from "firebase-error-translator/build"
import { TranslateObject, TranslateDictionary } from "firebase-error-translator/build/types";
import { Box, CircularProgress } from "@mui/material";
import { getProducts, getStripePayments, onCurrentUserSubscriptionUpdate } from "@stripe/firestore-stripe-payments";


const errorDictionary: TranslateDictionary = [AuthEN[0], {
    ...AuthEN[1],
    "auth/wrong-password": {
        toDev: "The email and password you entered don't match",
        toClient: "The email and password you entered don't match"
    },
    "auth/email-already-in-use": {
        toDev:
          "The provided email is already in use by an existing user. Each user must have a unique email.",
        toClient:
          "The provided email is already in use by an existing user, please provide another email.",
      },
}]

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCa4YI9KAnOxH4GlXz0PIgHSeF8v8bgtFM",
    authDomain: "airtable-excel-81d8c.firebaseapp.com",
    projectId: "airtable-excel-81d8c",
    storageBucket: "airtable-excel-81d8c.appspot.com",
    messagingSenderId: "772652249436",
    appId: "1:772652249436:web:49fcb396941272cdcc1199",
    measurementId: "G-BNBLXZLGNJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app, "europe-west6")
getAnalytics(app)
const createPortalLink = httpsCallable(functions, "ext-firestore-stripe-payments-createPortalLink")

export const payments = getStripePayments(app, {
    productsCollection: "products",
    customersCollection: "customers",
});

type ContextState = {
    user: User | null;
    subscriptionStatus: SubscriptionType
    stripePortalLink?: string;
    auth: Auth;
    logout: () => void;
    registerWithEmailAndPassword: (name: string, email: string, password: string) => Promise<UserCredential>;
    logInWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential>;
    sendPasswordReset: (email: string) => Promise<void>;
    translateAuthError: (error: unknown) => TranslateObject;
};

export type SubscriptionType = "free" | "premium" | undefined

const FirebaseAuthContext =
    React.createContext<ContextState | undefined>(undefined);

const FirebaseAuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = React.useState<User | null>(null);
    const [subscriptionStatus, setSubscriptionStatus] = React.useState<SubscriptionType>(undefined)
    const [stripePortalLink, setStripePortalLink] = React.useState<string>()
    const [loading, setLoading] = React.useState<boolean>(true)

    React.useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            setLoading(false)
            setUser(user)
        });
        return unsubscribe;
    }, []);

    React.useEffect(() => {
        if (user) {
            const unsubscribe = onCurrentUserSubscriptionUpdate(payments, async (subUpdate) => {
                setLoading(true)
                const products = (await getProducts(payments)).filter(p => p.active)
                // Subscription is active for the Premium product
                const isPremium = subUpdate.subscriptions.some(s => (s.status==="active" || s.status==="trialing") && products.find(p => p.id === s.product)?.name ==="Premium")
                setSubscriptionStatus(isPremium ? "premium" : "free")
                if (isPremium) {
                    const portalLinkObj: any = await createPortalLink({ returnUrl: window.location.origin })
                    setStripePortalLink(portalLinkObj.data.url)
                } else {
                    setStripePortalLink(undefined)
                }
                setLoading(false)
            });
            return unsubscribe;
        } else {
            setSubscriptionStatus(undefined)
            setStripePortalLink(undefined)
        }
    }, [user]);

    const logInWithEmailAndPassword = async (email: string, password: string) => {
        return await signInWithEmailAndPassword(auth, email, password);
    };

    const registerWithEmailAndPassword = async (name: string, email: string, password: string): Promise<UserCredential> => {
        const user = await createUserWithEmailAndPassword(auth, email, password);
        updateProfile(auth.currentUser!, { displayName: name })
        return user;
    };
    const sendPasswordReset = async (email: string) => {
        return await sendPasswordResetEmail(auth, email);
    };

    const logout = () => {
        signOut(auth);
    };

    const translateAuthError = (error: any): TranslateObject => {
        return translate(error, errorDictionary)
    }


    if (loading) {
        return <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
            height="100vh">
            <CircularProgress />
        </Box>
    }

    const value = {
        user,
        subscriptionStatus,
        auth,
        stripePortalLink,
        logout,
        sendPasswordReset,
        registerWithEmailAndPassword,
        logInWithEmailAndPassword,
        translateAuthError,
    };
    return (
        <FirebaseAuthContext.Provider value={value}>
            {children}
        </FirebaseAuthContext.Provider>
    );
};


function useFirebaseAuth() {
    const context = React.useContext(FirebaseAuthContext);
    if (context === undefined) {
        throw new Error(
            "useFirebaseAuth must be used within a FirebaseAuthProvider"
        );
    }
    return context;
}

export { FirebaseAuthProvider, useFirebaseAuth, db };