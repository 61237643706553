import React from 'react';
import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link as RouterLink } from "react-router-dom"
import { Link, Stack, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import { useFirebaseAuth } from '../../../FirebaseAuthContext';

// ----------------------------------------------------------------------

type ResetFormData = {
    email: string;
}


export default function ResetForm() {
    const navigate = useNavigate();

    const { user, translateAuthError, sendPasswordReset } = useFirebaseAuth()
    const [firebaseError, setFirebaseError] = useState<string>()

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    });

    const defaultValues = {
        email: '',
        password: '',
        remember: true,
    };

    const methods = useForm<ResetFormData>({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
        setError
    } = methods;

    React.useEffect(() => {
        if (user) {
            navigate("/dashboard/app", { replace: true });
        }
    }, [navigate, user])

    const onSubmit = async (data: ResetFormData) => {
        // setError(null)
        try {
            console.log("Reset password!!!")
            await sendPasswordReset(data.email);
        } catch (e) {
            const translation = translateAuthError(e)
            setFirebaseError(translation.toClient)
            console.warn(translation.toDev)
        }
    }

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
                <RHFTextField name="email" label="Email address" />
            </Stack>
            <Typography color="error">
                {firebaseError}
            </Typography>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ my: 2 }}>
                Reset Password
            </LoadingButton>
        </FormProvider>
    );
}
