import { Chip } from "@mui/material";
import React from "react";
import { SubscriptionType } from "../FirebaseAuthContext";

const SubscriptionStatusChip = ({ status }: { status: SubscriptionType }) => {
    const label = status === "free" ? "Free" : "Premium"
    const color = status === "free" ? "primary" : "success"
    return < Chip label={label} color={color} size="small" />
}

export default SubscriptionStatusChip