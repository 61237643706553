import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { payments, useFirebaseAuth } from '../../FirebaseAuthContext';
import { createCheckoutSession, getProducts, Product } from '@stripe/firestore-stripe-payments';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Alert, Link, Snackbar } from '@mui/material';

function PricingContent() {
    const [products, setProducts] = React.useState<Product[]>([])
    const { user, subscriptionStatus } = useFirebaseAuth()
    const navigate = useNavigate()
    const isPremium = subscriptionStatus === 'premium'
    const [stripeLoading, setStripeLoading] = React.useState<boolean>(false)
    // const [interval, setInterval] = React.useState<'month' | 'year'>('month')
    const [error, setError] = React.useState<boolean>(false);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setError(false);
    };


    React.useEffect(() => {
        getProducts(payments, {
            includePrices: true,
            activeOnly: true,
        }).then(products => setProducts(products))

    }, [setProducts])

    const premium = products.find(p => p.name === "Premium")
    const priceMonthly = premium?.prices.find(p => p.active && p.interval === "month")
    const priceYearly = premium?.prices.find(p => p.active && p.interval === "year")
    if (!premium || !priceMonthly || !priceYearly) {
        return <div>Something went wrong, we couldn't load plans</div>
    }


    const checkout = async (priceId: string | undefined) => {
        setStripeLoading(true)
        setError(false)
        try {
            const session = await createCheckoutSession(payments, {
                price: priceId || "",
                allow_promotion_codes: true
            });
            window.location.assign(session.url);
        } catch (e) {
            setError(true)
        }
        setStripeLoading(false)
    }

    type Tier = {
        title: string;
        price?: number | string;
        priceId?: string;
        subheader?: string | React.ReactNode;
        description: string[];
        buttonText: string;
        onClick: () => void;
        disabled?: boolean;
        loading: boolean

    }
    const freeTier: Tier =
    {
        title: 'Basic',
        subheader: 'Main features, for smaller tables',
        price: '0',
        description: [
            'Maximum 100 rows in exports',
            'Single sheet',
            'Excel table themes',
            'Number formats',
            'Unlimited downloads',
            'Email support',
        ],
        buttonText: 'Sign up for free',
        disabled: !!user,
        loading: false,
        onClick: () => navigate("/register")
    }
    const premiumTierMonth: Tier = {
        title: 'Premium Monthly',
        subheader: 'Best to get started',
        price: priceMonthly.unit_amount! / 100,
        priceId: priceMonthly.id,
        description: [
            'Up to 50,000 rows per sheet',
            'Image exports',
            'Multiple sheets per file',
            'Custom file & sheet name',
            'No watermark in Excel files',
            'Unlimited downloads',
            'Priority email support',
        ],
        buttonText: `Subscribe monthly`,
        disabled: !!user && isPremium,
        loading: stripeLoading,
        onClick: () => user ? checkout(priceMonthly.id) : navigate("/login")
    }

    const premiumTierYear: Tier = {
        title: 'Premium Yearly',
        subheader: "Most popular",
        price: priceYearly.unit_amount! / 100,
        priceId: priceYearly.id,
        description: [
            'Up to 50,000 rows per sheet',
            'Image exports',
            'Multiple sheets per file',
            'Custom file & sheet name',
            'No watermark in Excel files',
            'Unlimited downloads',
            'Priority email support',
        ],
        buttonText: `Subscribe yearly`,
        disabled: !!user && isPremium,
        loading: stripeLoading,
        onClick: () => user ? checkout(priceYearly.id) : navigate("/login")
    }

    return (
        <React.Fragment>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <Container component="main">
                <Grid container spacing={5}alignItems="stretch">
                    <Grid
                        item
                        key={freeTier.title}
                        xs={12}
                        md={4}
                    >
                        <Card style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <CardHeader
                                title={freeTier.title}
                                subheader={freeTier.subheader}
                                titleTypographyProps={{ align: 'center' }}
                                // action={tier.title === 'Premium' ? <StarIcon /> : null}
                                subheaderTypographyProps={{
                                    align: 'center',
                                    color: 'text.primary'
                                }}
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'light'
                                            ? theme.palette.primary.light
                                            : theme.palette.grey[700],
                                    padding: 1
                                    // paddingBottom: 4
                                }}
                            />
                            <CardContent style={{ flexGrow: 1 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'baseline',
                                        mb: 2,
                                    }}
                                >
                                    <Typography component="h2" variant="h3" color="text.primary">
                                        Free
                                    </Typography>
                                </Box>
                                <ul>
                                    {freeTier.description.map((line) => (
                                        <Typography
                                            component="li"
                                            variant="subtitle1"
                                            align="center"
                                            key={line}
                                        >
                                            {line}
                                        </Typography>
                                    ))}
                                </ul>
                            </CardContent>
                            <CardActions>
                                <LoadingButton
                                    fullWidth
                                    variant="contained"
                                    onClick={freeTier.onClick}
                                    disabled={freeTier.disabled}
                                    loading={freeTier.loading}
                                >
                                    {freeTier.buttonText}
                                </LoadingButton>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid
                        item
                        key={premiumTierMonth.title}
                        xs={12}
                        md={4}
                    >
                        <Card style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <CardHeader
                                title={premiumTierMonth.title}
                                subheader={premiumTierMonth.subheader}
                                titleTypographyProps={{ align: 'center' }}
                                // action={tier.title === 'Premium' ? <StarIcon /> : null}
                                subheaderTypographyProps={{
                                    align: 'center',
                                    color: 'text.primary'
                                }}
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'light'
                                            ? theme.palette.primary.light
                                            : theme.palette.grey[700],
                                    padding: 1
                                    // paddingBottom: 4
                                }}
                            />
                            <CardContent style={{ flexGrow: 1 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'baseline',
                                        mb: 2,
                                    }}
                                >
                                    <Typography component="h2" variant="h3" color="text.primary">
                                        ${premiumTierMonth.price}
                                    </Typography>
                                    <Typography variant="h6" color="text.secondary">
                                        /month
                                    </Typography>
                                </Box>
                                <ul>
                                    {premiumTierMonth.description.map((line) => (
                                        <Typography
                                            component="li"
                                            variant="subtitle1"
                                            align="center"
                                            key={line}
                                        >
                                            {line}
                                        </Typography>
                                    ))}
                                </ul>
                            </CardContent>
                            <CardActions>
                                <LoadingButton
                                    fullWidth
                                    variant="contained"
                                    onClick={premiumTierMonth.onClick}
                                    disabled={premiumTierMonth.disabled}
                                    loading={premiumTierMonth.loading}
                                >
                                    {premiumTierMonth.buttonText}
                                </LoadingButton>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid
                        item
                        key={premiumTierYear.title}
                        xs={12}
                        md={4}
                    >
                        <Card style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                            <CardHeader
                                title={premiumTierYear.title}
                                subheader={premiumTierYear.subheader}
                                titleTypographyProps={{ align: 'center' }}
                                // action={tier.title === 'Premium' ? <StarIcon /> : null}
                                subheaderTypographyProps={{
                                    align: 'center',
                                    color: 'text.primary'
                                }}
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'light'
                                            ? theme.palette.primary.light
                                            : theme.palette.grey[700],
                                    padding: 1
                                    // paddingBottom: 4
                                }}
                            />
                            <CardContent style={{ flexGrow: 1 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'baseline',
                                        mb: 2,
                                    }}
                                >
                                    <Typography component="h2" variant="h3" color="text.primary">
                                        ${premiumTierYear.price}
                                    </Typography>
                                    <Typography variant="h6" color="text.secondary">
                                        /year
                                    </Typography>
                                </Box>
                                <ul>
                                    {premiumTierYear.description.map((line) => (
                                        <Typography
                                            component="li"
                                            variant="subtitle1"
                                            align="center"
                                            key={line}
                                        >
                                            {line}
                                        </Typography>
                                    ))}
                                </ul>
                            </CardContent>
                            <CardActions>
                                <LoadingButton
                                    fullWidth
                                    variant="contained"
                                    onClick={premiumTierYear.onClick}
                                    disabled={premiumTierYear.disabled}
                                    loading={premiumTierYear.loading}
                                >
                                    {premiumTierYear.buttonText}
                                </LoadingButton>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <Snackbar open={error} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Something went wrong, please contact us at <Link href="mailto:contact@excel-exporter.com">contact@excel-exporter.com</Link>
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default function Pricing() {
    return <PricingContent />;
}