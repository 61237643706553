import PropTypes from 'prop-types';
// icons
import { Icon, IconifyIcon } from '@iconify/react';
// @mui
import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

// ----------------------------------------------------------------------

export default function Iconify({ icon, sx, ...other }: { icon: string | IconifyIcon; sx?: SxProps<Theme> }) {
  return <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />;
}
